import { render, staticRenderFns } from "./SuperannuationAppraisals.vue?vue&type=template&id=eec2bb68&scoped=true&"
import script from "./SuperannuationAppraisals.vue?vue&type=script&lang=ts&"
export * from "./SuperannuationAppraisals.vue?vue&type=script&lang=ts&"
import style0 from "./SuperannuationAppraisals.vue?vue&type=style&index=0&id=eec2bb68&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eec2bb68",
  null
  
)

export default component.exports