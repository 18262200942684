












































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Listing } from 'client-website-ts-library/types/Listing';

@Component
export default class ListingCard extends Vue {
  @Prop()
  private readonly listing!: Listing;

  get isNew(): boolean {
    const newThreshold = (new Date().getTime()) - 86400 * 7 * 1000;

    return new Date(this.listing.CreateDate).getTime() >= newThreshold;
  }

  get formattedAddressLine1(): string {
    const parts = [];

    if (this.listing.Address.UnitNumber) {
      parts.push(`${this.listing.Address.UnitNumber}/${this.listing.Address.StreetNumber}`);
    } else {
      parts.push(this.listing.Address.StreetNumber);
    }

    parts.push(this.listing.Address.Address);

    return parts.join(' ');
  }

  get formattedAddressLine2(): string {
    return `${this.listing.Address.Suburb}, ${this.listing.Address.State} ${this.listing.Address.PostCode}`;
  }

  get formattedDateAvailable(): string {
    if (!this.listing.DateAvailable) return 'now';

    const now = new Date();
    const dateAvailable = new Date(this.listing.DateAvailable);

    if (dateAvailable <= now) return 'now';

    return `${dateAvailable.getDate()}/${(dateAvailable.getMonth() + 1)}/${dateAvailable.getFullYear().toString().substr(2, 2)}`;
  }

  get isCommercial(): boolean {
    return this.listing.CommercialCategories?.length > 0;
  }

  get formattedMos(): string {
    switch (this.listing.MethodOfSale) {
      case 'Sale':
        return 'For Sale';
      case 'Lease':
        return 'For Rent';
      default:
        return this.listing.MethodOfSale;
    }
  }
}
