































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Profile } from 'client-website-ts-library/types/Profile';

@Component
export default class ProfileCard extends Vue {
  @Prop()
  private readonly profile!: Profile;

  private parallaxX = 0;

  private parallaxY = 0;

  private handleOrientationUpdate(data: DeviceOrientationEvent) {
    if (data.gamma) this.parallaxX = data.gamma * -0.5;
    if (data.beta) this.parallaxY = data.beta * -0.5;
  }

  mounted() {
    window.addEventListener('deviceorientation', this.handleOrientationUpdate);
  }

  beforeDestroy() {
    window.removeEventListener('deviceorientation', this.handleOrientationUpdate);
  }
}
