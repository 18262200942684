








































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Listing, Profile } from 'client-website-ts-library/types';
import { ServiceManager, ServiceType } from 'client-website-ts-library/services';
import { AnalyticsEvent, AnalyticsEventObject, AnalyticsEventType, IAnalyticsAdapter, AnalyticsEventPriority } from 'client-website-ts-library/services/Analytics';
import { FormConstructorData } from 'client-website-ts-library/types/Forms';
import { ContextItemType } from 'client-website-ts-library/types/Context';
import Form from './Forms/Form.vue';

@Component({
  components: {
    Form,
  },
})
export default class ListingAgentProfile extends Vue {
  @Prop()
  private readonly listing!: Listing;

  private getShortAgentProfile(profile: Profile): string {
    const tmp = document.createElement('div');

    tmp.innerHTML = profile.AboutAgent;

    const text = tmp.innerText;

    return text.length < 120 ? text : `${tmp.innerText.substring(0, 100)}...`;
  }

  private formData: FormConstructorData | null = null;

  created() {
    this.formData = new FormConstructorData([
      {
        Type: ContextItemType.Listing,
        Id: this.listing.Id,
      },
    ]);
  }

  handlePhoneClicked(profile: Profile) {
    const analyticsService = ServiceManager.Get<IAnalyticsAdapter>(ServiceType.Analytics, 'PROListAnalyticsAdapter');

    const evt = new AnalyticsEvent(AnalyticsEventType.CallAgentClicked, AnalyticsEventObject.FromProfile(profile), AnalyticsEventPriority.Realtime);

    analyticsService!.Record(evt);
  }
}
