
















































































































































































































































































































































































































































































































import { API, Config } from 'client-website-ts-library/services';
import { Component, Vue } from 'vue-property-decorator';
import ListingSearch from '../components/ListingSearch.vue';

@Component({
  components: {
    ListingSearch,
  },
})
export default class PrivacyPolicy extends Vue {
  private businessName: string | null = null;

  mounted() {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.businessName = office.FranchiseName;
    });
  }
}
