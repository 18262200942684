













































import { Component, Mixins } from 'vue-property-decorator';

import { ListingFilter } from 'client-website-ts-library/filters';
import {
  ListingStatus,
  ListingCategory,
  Listing,
} from 'client-website-ts-library/types';

import { View } from 'client-website-ts-library/plugins';
import { API } from 'client-website-ts-library/services';

import ListingSearch from '@/components/ListingSearch.vue';
import ListingCarousel from '@/components/ListingCarousel.vue';
import FeaturedListingCard from '../components/FeaturedListingCard.vue';
import Listings from '../components/Listings.vue';
import ItemCycler from '../components/Cycler/ItemCycler.vue';

import { ItemCyclerSlide, ItemCyclerImageSlide } from '../app_code/ItemCycler';

@Component({
  components: {
    ListingSearch,
    Listings,
    ItemCycler,
    FeaturedListingCard,
    ListingCarousel,
  },
})
export default class Home extends Mixins(View) {
  private listingFilterFeatured = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    IsFeatured: true,
    Categories: [
      ListingCategory.ResidentialSale,
      ListingCategory.ResidentialLand,
    ],
    PageSize: 3,
  });

  private featuedListings: Listing[] = [];

  private listingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    Categories: [
      ListingCategory.ResidentialSale,
      ListingCategory.ResidentialLand,
      ListingCategory.Commercial,
    ],
    PageSize: 6,
  });

  private cyclerImages: ItemCyclerSlide[] = [
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/1', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/2', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/3', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
  ];

  mounted() {
    API.Listings.Search(this.listingFilterFeatured).then((results) => {
      this.featuedListings = results.Items;
    });
  }
}
