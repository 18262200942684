
















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ToggleButton extends Vue {
  @Prop({ default: 'var(--brand-primary)' })
  private readonly selectedBackgroundColour!: string;

  @Prop({ default: 'var(--brand-primary-complement)' })
  private readonly selectedColour!: string;

  @Prop({ default: 'var(--brand-secondary)' })
  private readonly backgroundColour!: string;

  @Prop({ default: 'var(--brand-secondary-complement)' })
  private readonly colour!: string;

  @Prop({ required: true })
  private readonly items!: string[];

  @Prop()
  private readonly default!: string;

  private selected: string | null = this.default;

  select(item: string) {
    this.selected = item;

    this.$emit('change', this.selected);
  }
}
