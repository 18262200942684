





































import { Listing } from 'client-website-ts-library/types';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class FeaturedListingCard extends Vue {
  @Prop()
  private readonly listing!: Listing;

  @Prop({ default: false })
  private readonly mini!: boolean;
}
